import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDogrulama_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDogrulama_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  IsVerificationCodeValid: boolean;
  IsVerificationCodeValid_dummy: boolean;
  goToNextScreenAfterVerifyCode: any;
  goToNextScreenAfterVerifyCode_dummy: any;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  isComp528713Visible: "visible" | "hidden";
}

export class Dogrulama_ScreenBase extends React.PureComponent<IDogrulama_ScreenProps, any> {
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "635e91a4-014e-425c-a66f-29a9d0f44b05",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 722774, PropertyName: "value", Value: "Lütfen Doğrulama Kodunu Giriniz." },
        { Id: 617467, PropertyName: "placeholder", Value: "Doğrulama Kodu" },
        { Id: 854781, PropertyName: "label", Value: "Gönder" },
        { Id: 384658, PropertyName: "value", Value: "Back to" },
        { Id: 28660, PropertyName: "value", Value: "Sign in" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      IsVerificationCodeValid: false,
      goToNextScreenAfterVerifyCode: "",
      NAVIGATE: "",
      isComp528713Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("dogrulama", "");
      return;
    }

    if (true) {
    }

    window.addEventListener("keydown", this.onKeyDown);

    KuikaAppManager.calculateAndSetBodyHeight("dogrulama", "");
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      var element = document.getElementById("854781");
      if (element && element.click) {
        element.click();
      }
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("dogrulama", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      dogrulama_617467_value: undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  DogrulamaComponent_854781_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dogrulama_617467_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "dogrulama_617467_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.IsVerificationCodeValid = await ReactSystemFunctions.isVerificationCodeValid(
      this,
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(this, "dogrulama_617467_value", "value", "", "", "")
      )
    );
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToNextScreenAfterVerifyCode(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DogrulamaComponent_28660_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Dogrulama",
      "Signin",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [] as number[];
  }
}
