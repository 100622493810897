import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IUrunList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IUrunList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Notify: boolean;
  Notify_dummy: boolean;
  UploadFile: string;
  UploadFile_dummy: string;
  ImportExcelBase64: string;
  ImportExcelBase64_dummy: string;
  Saveexcelbinary: number;
  Saveexcelbinary_dummy: number;
  sbEserTipiList: any[];
  sbEserTipiList_dummy: any[];
  getFilterParametersByUserName: any[];
  getFilterParametersByUserName_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  spGetListStok: any[];
  spGetListStok_dummy: any[];
  spGetListStokCount: any[];
  spGetListStokCount_dummy: any[];
  FilterParametersSaveUrunList: number;
  FilterParametersSaveUrunList_dummy: number;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  DeleteStok: number;
  DeleteStok_dummy: number;
  isComp196686Visible: "visible" | "hidden";
  isComp523126Visible: "visible" | "hidden";
  isComp477372Visible: "visible" | "hidden";
  isComp741996Visible: "visible" | "hidden";
  isComp67631Visible: "visible" | "hidden";
  isComp34996Visible: "visible" | "hidden";
  isComp271413Visible: "visible" | "hidden";
  isComp191378Visible: "visible" | "hidden";
  isComp452085Visible: "visible" | "hidden";
  isComp816597Visible: "visible" | "hidden";
  isComp638642Visible: "visible" | "hidden";
  isCompurunlist_594883AuthorizationVisible: "visible" | "hidden";
  isCompurunlist_167541AuthorizationVisible: "visible" | "hidden";
  isCompurunlist_211653AuthorizationVisible: "visible" | "hidden";
}

export class UrunList_ScreenBase extends React.PureComponent<IUrunList_ScreenProps, any> {
  urunlist_341027_value_kuikaSelectBoxRef: React.RefObject<any>;
  urunlist_480485_value_kuikaTableRef: React.RefObject<any>;
  urunlist_39854_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "931e1614-125f-408b-8baf-daf9be5e2a5d",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 513636, PropertyName: "value", Value: "ESERLER" },
        { Id: 341027, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 606520, PropertyName: "placeholder", Value: "Aranacak kelimeyi yazınız..." },
        { Id: 480485, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 425331, PropertyName: "title", Value: "ESER TİPİ" },
        { Id: 312985, PropertyName: "value", Value: "[datafield:esertipi]" },
        { Id: 458076, PropertyName: "title", Value: "Eser Adı" },
        { Id: 964832, PropertyName: "value", Value: "[datafield:stokad]" },
        { Id: 67631, PropertyName: "title", Value: "ESER SAHİBİ" },
        { Id: 589780, PropertyName: "value", Value: "[datafield:esersahibis]" },
        { Id: 581437, PropertyName: "value", Value: "[datafield:sanatciadi]" },
        { Id: 34996, PropertyName: "title", Value: "SANATÇI ADI" },
        { Id: 817860, PropertyName: "value", Value: "[datafield:sanatciadi]" },
        { Id: 271413, PropertyName: "title", Value: "Ana Katalog" },
        { Id: 754316, PropertyName: "value", Value: "[datafield:anakatalogad]" },
        { Id: 191378, PropertyName: "title", Value: "Alt Katalog" },
        { Id: 605949, PropertyName: "value", Value: "[datafield:altkatalogad]" },
        { Id: 196686, PropertyName: "title", Value: "Albüm Kodu" },
        { Id: 712096, PropertyName: "value", Value: "[datafield:albumkodu]" },
        { Id: 523126, PropertyName: "title", Value: "Albüm Adı" },
        { Id: 404367, PropertyName: "value", Value: "[datafield:albumad]" },
        { Id: 477372, PropertyName: "title", Value: "Track No" },
        { Id: 838727, PropertyName: "value", Value: "[datafield:trackno]" },
        { Id: 452085, PropertyName: "title", Value: "Temsilcilik Durumu" },
        { Id: 284137, PropertyName: "value", Value: "[datafield:temsilcilikdurumu]" },
        { Id: 816597, PropertyName: "title", Value: "L.Alt Katalog" },
        { Id: 699845, PropertyName: "value", Value: "[datafield:logoaltkatalogkodu]" },
        { Id: 638642, PropertyName: "title", Value: "L.Stok Kod" },
        { Id: 373023, PropertyName: "value", Value: "[datafield:logostokkodu]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.urunlist_341027_value_kuikaSelectBoxRef = React.createRef();
    this.urunlist_606520_value_kuikaTextInputRef = React.createRef();
    this.urunlist_480485_value_kuikaTableRef = React.createRef();
    this.urunlist_39854_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Notify: false,
      UploadFile: "",
      ImportExcelBase64: "",
      Saveexcelbinary: "",
      sbEserTipiList: [],
      getFilterParametersByUserName: [],
      SetValueOf: "",
      spGetListStok: [],
      spGetListStokCount: [],
      FilterParametersSaveUrunList: "",
      ChangeVisibilityOf: "",
      DeleteStok: "",
      isComp196686Visible: "hidden",
      isComp523126Visible: "hidden",
      isComp477372Visible: "hidden",
      isComp741996Visible: "hidden",
      isComp67631Visible: "hidden",
      isComp34996Visible: "hidden",
      isComp271413Visible: "hidden",
      isComp191378Visible: "hidden",
      isComp452085Visible: "hidden",
      isComp816597Visible: "hidden",
      isComp638642Visible: "hidden",
      isCompurunlist_594883AuthorizationVisible: "visible",
      isCompurunlist_167541AuthorizationVisible: "visible",
      isCompurunlist_211653AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("urunlist", "");
      return;
    }

    if (true) {
      await this.UrunListPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompurunlist_594883AuthorizationVisible", [
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "3699f6cb-db2c-cd20-74c6-a9ef5bd8a887",
      "Admin",
      "Muz Ekip A",
      "Muz Ekip B"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompurunlist_167541AuthorizationVisible", [
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "Admin"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompurunlist_211653AuthorizationVisible", [
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "3699f6cb-db2c-cd20-74c6-a9ef5bd8a887",
      "Admin",
      "Muz Ekip A",
      "Muz Ekip B"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("urunlist", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("urunlist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.UrunListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      urunlist_341027_value: this.state.getFilterParametersByUserName?.at?.(0)?.urunListSBox ?? undefined,
      urunlist_606520_value: this.state.getFilterParametersByUserName?.at?.(0)?.urunListSearch ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  UrunListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UrunList/UrunListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbEserTipiList = result?.data.sbEserTipiList;

    formVars.urunlist_341027_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].urunListSBox
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].urunListSBox
        : null;
    formVars.urunlist_341027_options = stateVars.sbEserTipiList;
    stateVars.getFilterParametersByUserName = result?.data.getFilterParametersByUserName;
    formVars.urunlist_341027_value =
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].urunListSBox
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].urunListSBox
        : null;
    formVars.urunlist_341027_options = stateVars.sbEserTipiList;
    formVars.urunlist_606520_value = ReactSystemFunctions.toString(
      this,
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].urunListSearch
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].urunListSearch
        : null
    );
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UrunListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UrunListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("urunlist_341027_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getFilterParametersByUserName?.length > 0
          ? stateVars.getFilterParametersByUserName[0].urunListSBox
          : this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0].urunListSBox
          : null
      ),
      null,
      "Id"
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("urunlist_606520_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getFilterParametersByUserName?.length > 0
          ? stateVars.getFilterParametersByUserName[0].urunListSearch
          : this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0].urunListSearch
          : null
      ),
      null
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UrunListPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  UrunListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_606520_value", "value", "", "", "")
        ),
        "string"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_39854_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_39854_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      EserTipiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "id")
        ),
        "Guid"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_606520_value", "value", "", "", "")
        ),
        "string"
      ),
      EserTipiId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "id")
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UrunList/UrunListPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spGetListStok = result?.data.spGetListStok;

    stateVars.spGetListStokCount = result?.data.spGetListStokCount;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UrunListPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UrunListPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.urunlist_341027_value = ReactSystemFunctions.toString(
      this,
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].urunListSBox
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].urunListSBox
        : null
    );

    stateVars.dataSource_341027 = this.state.sbEserTipiList;
    stateVars.dataSource_341027 = this.state.sbEserTipiList;
    formVars.urunlist_606520_value = ReactSystemFunctions.toString(
      this,
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].urunListSearch
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].urunListSearch
        : null
    );

    stateVars.dataSource_480485 = this.state.spGetListStok;
    stateVars.isComp67631Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "kod")
        ),
        "B"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp34996Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "kod")
        ),
        "R"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp271413Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "kod")
        ),
        "B"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp191378Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "kod")
        ),
        "B"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp196686Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "kod")
        ),
        "T"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp523126Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "kod")
        ),
        "T"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp477372Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "kod")
        ),
        "T"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452085Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "kod")
        ),
        "B"
      ) === true
        ? "visible"
        : "hidden";
    formVars.urunlist_39854_total = ReactSystemFunctions.value(
      this,
      stateVars.spGetListStokCount?.length > 0
        ? stateVars.spGetListStokCount[0].sumCount
        : this.state.spGetListStokCount?.length > 0
        ? this.state.spGetListStokCount[0].sumCount
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  UrunListComponent_798391_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UrunList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "294787",
      ReactSystemFunctions.convertToTypeByName("", "string"),
      "left",
      null,
      "",
      "",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UrunListComponent_594883_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "UrunKart",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    );
    KuikaAppManager.addToPageInputVariables(
      "UrunKart",
      "NewEdit",
      ReactSystemFunctions.convertToTypeByName("N", "string")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UrunList",
      "UrunKart",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UrunListComponent_741996_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "d1546484_7c7b_0fcf_e282_dc28c093c62f_notify",
        this.defaultML,
        "Banka Eserleri Aktarılır"
      ),
      ReactSystemFunctions.convertToTypeByName("default", "string"),
      ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
      ReactSystemFunctions.convertToTypeByName("5", "number"),
      null,
      null,
      null
    );
    stateVars.UploadFile = await ReactSystemFunctions.uploadFile();

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UrunListComponent_741996_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  UrunListComponent_741996_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      excelBase64_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile),
        "string"
      ),
      base64xls_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UrunList/UrunListComponent_741996_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ImportExcelBase64 = result?.data.importExcelBase64;
    stateVars.Saveexcelbinary = result?.data.saveexcelbinary;
    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  UrunListComponent_341027_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UrunListSearch_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_606520_value", "value", "", "", "")
        ),
        "string"
      ),
      UrunListSBox_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "id")
        ),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_606520_value", "value", "", "", "")
        ),
        "string"
      ),
      PageNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_39854_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_39854_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      EserTipiId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "id")
        ),
        "Guid"
      ),
      Search_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_606520_value", "value", "", "", "")
        ),
        "string"
      ),
      EserTipiId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UrunList/UrunListComponent_341027_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterParametersSaveUrunList = result?.data.filterParametersSaveUrunList;
    stateVars.spGetListStok = result?.data.spGetListStok;

    stateVars.spGetListStokCount = result?.data.spGetListStokCount;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UrunListComponent_341027_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UrunListComponent_341027_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("urunlist_39854_value", "string"),
      ReactSystemFunctions.convertToTypeByName("1", "any"),
      null
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UrunListComponent_341027_onChange2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  UrunListComponent_341027_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "kod")
        ),
        "R"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp196686Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunListComponent_341027_onChange3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunListComponent_341027_onChange3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  UrunListComponent_341027_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "kod")
        ),
        "R"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp523126Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunListComponent_341027_onChange4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunListComponent_341027_onChange4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  UrunListComponent_341027_onChange4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "kod")
        ),
        "R"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp477372Visible", "string"),
        ReactSystemFunctions.convertToTypeByName("hidden", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunListComponent_341027_onChange5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunListComponent_341027_onChange5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  UrunListComponent_341027_onChange5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_480485 = this.state.spGetListStok;
    stateVars.isComp67631Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "kod")
        ),
        "B"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp34996Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "kod")
        ),
        "R"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp271413Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "kod")
        ),
        "B"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp191378Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "kod")
        ),
        "B"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp196686Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "kod")
        ),
        "T"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp523126Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "kod")
        ),
        "T"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp477372Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "kod")
        ),
        "T"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452085Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "kod")
        ),
        "B"
      ) === true
        ? "visible"
        : "hidden";
    formVars.urunlist_39854_total = ReactSystemFunctions.value(
      this,
      stateVars.spGetListStokCount?.length > 0
        ? stateVars.spGetListStokCount[0].sumCount
        : this.state.spGetListStokCount?.length > 0
        ? this.state.spGetListStokCount[0].sumCount
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UrunListComponent_606520_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UrunListSearch_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_606520_value", "value", "", "", "")
        ),
        "string"
      ),
      UrunListSBox_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "id")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UrunList/UrunListComponent_606520_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FilterParametersSaveUrunList = result?.data.filterParametersSaveUrunList;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UrunListComponent_606520_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UrunListComponent_606520_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("urunlist_39854_value", "string"),
      ReactSystemFunctions.convertToTypeByName("1", "any"),
      null
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UrunListComponent_606520_onChange2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  UrunListComponent_606520_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_606520_value", "value", "", "", "")
        ),
        "string"
      ),
      EserTipiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "id")
        ),
        "Guid"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_39854_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_39854_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_606520_value", "value", "", "", "")
        ),
        "string"
      ),
      EserTipiId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "id")
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UrunList/UrunListComponent_606520_onChange2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spGetListStok = result?.data.spGetListStok;

    stateVars.spGetListStokCount = result?.data.spGetListStokCount;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UrunListComponent_606520_onChange3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UrunListComponent_606520_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_480485 = this.state.spGetListStok;
    formVars.urunlist_39854_total = ReactSystemFunctions.value(
      this,
      stateVars.spGetListStokCount?.length > 0
        ? stateVars.spGetListStokCount[0].sumCount
        : this.state.spGetListStokCount?.length > 0
        ? this.state.spGetListStokCount[0].sumCount
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UrunListComponent_167541_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "urunlist_480485_value", "id"),
        "Guid"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_606520_value", "value", "", "", "")
        ),
        "string"
      ),
      PageNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_39854_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_39854_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      EserTipiId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "id")
        ),
        "Guid"
      ),
      Search_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_606520_value", "value", "", "", "")
        ),
        "string"
      ),
      EserTipiId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "56bec9d8_e487_b977_bf18_3d303fbb8603_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "UrunList/UrunListComponent_167541_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DeleteStok = result?.data.deleteStok;
      stateVars.spGetListStok = result?.data.spGetListStok;

      stateVars.spGetListStokCount = result?.data.spGetListStokCount;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UrunListComponent_167541_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UrunListComponent_167541_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_480485 = this.state.spGetListStok;
    formVars.urunlist_39854_total = ReactSystemFunctions.value(
      this,
      stateVars.spGetListStokCount?.length > 0
        ? stateVars.spGetListStokCount[0].sumCount
        : this.state.spGetListStokCount?.length > 0
        ? this.state.spGetListStokCount[0].sumCount
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UrunListComponent_211653_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "UrunKart",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "urunlist_480485_value", "id"), "Guid")
    );
    KuikaAppManager.addToPageInputVariables(
      "UrunKart",
      "NewEdit",
      ReactSystemFunctions.convertToTypeByName("E", "string")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UrunList",
      "UrunKart",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UrunListComponent_39854_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_606520_value", "value", "", "", "")
        ),
        "string"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_39854_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_39854_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      EserTipiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunlist_341027_value", "value", "sbEserTipiList", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UrunList/UrunListComponent_39854_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spGetListStok = result?.data.spGetListStok;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UrunListComponent_39854_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UrunListComponent_39854_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_480485 = this.state.spGetListStok;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [294787] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.UrunListPageInit();
    }
  }
}
